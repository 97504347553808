import React from 'react'
import Header from "../../components/ui-kit-header"
import Title from "../../components/title"
import *as Styles from "../../assets/css/ui-kit.css"

const UIIcons = (props) => {
    return (
        <div>
            <Header />
            <div className="Coloursimages">
                <Title title="Icons"/>
                <div className="Rectangle">
                    <Title title="Iconos genericos con 1,5px de grossor"/>
                    
                </div>
            </div>
        </div>
        )

}

export default UIIcons